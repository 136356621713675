.jtemplate-pagination {
    display:flex;
    cursor:pointer;
    margin-top:10px;
    margin-bottom:10px;
}

.jtemplate-pagination {
    display: flex;
    margin-bottom: 15px;
}

.jtemplate-pagination div {
    text-align:center;
    font-size:0.8em;
    width:30px;
    height:30px;
    line-height:30px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.jtemplate-pagination div:hover {
    background-color:#eee;
}

.jtemplate-pagination:empty {
    display:none;
}

.jtemplate-empty:empty {
    display:none;
}

.jtemplate-results {
    margin-top:15px;
    margin-bottom:15px;
}
